@import '@/shared/ui/Select/General.module.scss';

.select {
  position: relative;
}

.textarea {
  min-height: 162px;
  padding-right: 50px;
}

.wrap {
  margin-top: 20px;
  position: relative;
}

.button {
  width: 138px;
  margin-top: 40px;
}

.header {
  overflow: hidden;
}

.body {
  padding: 15px 27px;
  background: $fourth_color;
}

.reset {
  position: absolute;
  top: 12px;
  right: 18px;
}
