.wrapper {
    width: fit-content;
    padding: 20.46px 39px 19.54px 23px;
    background: #FFE8D9;
    border-radius: 5px;
    position: relative;
    color:#822300;
}

.border {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    background: #F9703E;
    border-radius: 5px;
    width: 6px;
    margin-right: 17px;
}