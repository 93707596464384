@import "@/assets/styles/utils/vars";

.itemWrapper:not(:last-child) {
    margin-bottom: 8px;
}

.keyFirst {
    margin-bottom: 11px;
}

.key {
    color: $main_link_color;
    line-height: 19px;
}

.value {
    color: $first_color;
    font-weight: 500;
    &.active{
        color: $done_color;
    }
}
