@import "@/assets/styles/utils/vars";

.breadCrumb {
    font-size: 12px;
    line-height: 16.8px;
    display: flex;
    column-gap: 10px;
    color: $second_color;
    margin-bottom: 30px;
}

.item {
    &:hover {
        text-decoration: underline !important;
        color: $main_link_color;
    }

}

.active {
    color: $main_link_color;

    :hover {
        // cursor: pointer;
        color: red($color: #000000) !important;
    }
}
