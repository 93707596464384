@import "@/assets/styles/utils/vars";
@import '@/shared/ui/Select/General.module.scss';

.title{
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: $first_color;
}
.button{
  width: 120px;
}
.select {
  width: 100%;
}
