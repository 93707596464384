@import '@/assets/styles/utils/vars';

.wrapper {
    row-gap: 2.75px;

    &:hover .item {
        background: rgb(95, 95, 179);
    }

    .item {
        background: $main_link_color;
        width: 3.67px;
        height: 3.67px;
        border-radius: 50%;

    }
}