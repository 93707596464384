.datePicker {
    width: 280px;

    input {
        padding: 0 14px;
        height: 40px;

        &:hover {
            border: unset;
        }
    }
}