.formItem {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    flex-basis: 226px;
    max-width: 226px;
    margin-bottom: 14px;
}

.formItemOranization {
    flex-basis: 700px;
    max-width: 100%;
    margin-right: 30px;
}

.form {
    column-gap: 10px;
    display: flex;
}

.saveButton {
    flex-basis: 180px;
    flex-shrink: 0;
}

