@import '@/assets/styles/utils/vars';

.wrapper {
    position: relative;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.input {
    cursor: pointer;

    opacity: 0;
    left: 0;
    top: 0;
    position: absolute;
}

.square {
    cursor: pointer;
    width: 16px;
    border-radius: 3px;
    height: 16px;
    border: 2px solid $third_color;
    display: block;
}

.input:checked+.square {
    border: none;
    background: url('../../../assets/icons/Check.svg') center center;
}