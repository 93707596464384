@import '@/assets/styles/utils/vars';

.tabs {
  display: flex;
  padding-bottom: 8px;
  border-bottom: 1px solid $third_color;
  column-gap: 25px;
  font-size: 14px;

  .item {
    cursor: pointer;
    &.active {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 0;
        height: 2px;
        width: 100%;
        background: $main_color;
      }
    }
  }
}
