//Generate font-size classes

@mixin generate-fs {
    @each $var in $font-size-list {
        .fs-#{$var}{
            font-size: $var * 1px;
        }
    }
}

//Generate font-weight

@mixin generate-fw {
    @each $var in $font-weight-list {
        .fw-#{$var}{
            font-weight: $var;
        }
    }
}

@mixin generate-width($value) {
    @for $i from 1 through $value{
        .w-#{$i}{
            width: $i * 1%;
            flex-basis: $i * 1%;
        }
    }
}

@mixin margin-classes($value) {
    @for $i from 1 through $value{
        .mb-#{$i}{
            margin-bottom: $i * 1px;
        }
        .mt-#{$i}{
            margin-top: $i * 1px;
        }
        .ml-#{$i}{
            margin-left: $i * 1px;
        }
        .mr-#{$i}{
            margin-right: $i * 1px;
        }
        .p-#{$i}{
            padding: $i * 1px;
        }
        .pr-#{$i}{
            padding-right: $i * 1px;
        }
        .pl-#{$i}{
            padding-left: $i * 1px;
        }
        .px-#{$i}{
            padding-right: $i * 1px;
            padding-left: $i * 1px;
        }
        .py-#{$i}{
            padding-top: $i * 1px;
            padding-bottom: $i * 1px;
        }
        .pt-#{$i}{
            padding-top: $i * 1px;
        }
        .pb-#{$i}{
            padding-bottom: $i * 1px;
        }
    }
}
