.group {
    height: 11.64px;
}

.hide {
    opacity: 0;
}

.show {
    opacity: 1;
}

.control {
    width: 8.36px;
    height: 4.64px;
    display: flex;
}

.down {
    transform: rotate(180deg);
    margin-top: 2.36px;
}