@import '@/shared/ui/Select/General.module.scss';

.setStatusRow {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.label {
    flex-shrink: 0;
}

.button {
    width: 120px;
}
.selectWrap{
    width: 380px;
}
