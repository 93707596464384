@import '@/assets/styles/utils/vars';

.title {
    position: relative;
    margin-bottom: 3px;
    padding: 10px 27px;
    color: $first_color;
    font-size: 14px;
    user-select: none;
}

.wrapper {
    max-width: 100%;
}

.arrow {
    position: absolute;
    top: 18px;
    right: 29.64px;
    width: 8.36px;
    height: 4.64px;
    border: 0 solid transparent;
    border-right-width: 5px;
    border-left-width: 5px;
    border-bottom: 5px solid $first_color;
    transition: all 0.3s;
}

.arrowRotate {
    transform: rotate(180deg);
}

.content {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 27px;
    padding-right: 27px;
    max-height: 0px;
    transition: all .3s;
    overflow: hidden;
}

.contentExpand {
    padding-top: 22px;
    padding-bottom: 22px;
    max-height: 1052px;
}