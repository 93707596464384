@import '@/assets/styles/utils/vars';

.wrap {
  position: relative;

  .wrapper {
    display: block;
  }

  .input {
    line-height: initial;
    font-size: 12px;
    padding: 11px 26px 12px 14px;
    border: 1px solid #E6E7E9;
    border-radius: 5px;
    width: 100%;

    &:hover, &:focus {
      border: 1px solid $main_color;
    }
  }

  .icon {
    position: absolute;
    top: 10px;
    right: 13px;
    pointer-events: none;
  }
}

