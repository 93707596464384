.modal {
    flex-basis: 622px;
    max-width: 622px;
}

.buttonUpload {
    flex-basis: 111px;
}
.fileInput{
    width: 0;
    height: 0;
    opacity: 0;
    padding: 0;
    margin: 0;
}

.successModal{
    max-width: 368px;
    flex-basis: 368px;
    padding: 41px 42px 61px 38px;
}