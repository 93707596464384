@import '@/assets/styles/utils/vars';
@import '@/shared/styles/arrow.module.scss';


.inputWrapper {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    input:-internal-autofill-selected {
        background: none;
    }


    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

%input {
    background: $fifth_color;
    border: 1px solid $third_color;
    height: 40px;
    border-radius: 5px;

    &::placeholder {
        font-size: 12px;
        color: $second_color;
    }

    &:focus,
    &:hover {
        border-color: $main_color;
    }
}



.input {
    flex-basis: 100%;
    padding: 11px 0px 12px 14px;
    @extend %input;

    &:disabled {
        color: $first_color;
    }


}

.disabled {
    background: $bg_color;
    color: $second_color !important;

    &:hover {
        border: 1px solid $third_color;

    }
}

.error {
    border-color: red;
}

.errorMessage {
    text-align: left;
    color: red;
    font-size: 14px;
}

.eye {
    position: absolute;
    top: 10px;
    right: 14px;
    cursor: pointer;
    height: 20px;
    width: 20px;
    mask-image: url('/assets/icons/eye-slash.svg');
    background-color: $second_color;

    &:hover {
        background-color: gray;
    }
}

.searchWrap {
    position: relative;
    width: 100%;
    //max-width: 706px
}

.searchIconWrapper {

    @extend %input;
    position: relative;
    left: 0;
    width: 100%;
    top: 0;
    display: block;
}

.iconContainer {
    position: absolute;
    width: 18px;
    height: 18px;
    z-index: 100;
    top: 50%;
    transform: translateY(-50%);
    left: 16.94px;
}

.inputIcon {
    width: 85%;
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

}

.inputIcon:focus~label {

    border-color: $main_color;
}

.inputSearchIcon {
    left: 47px;

}

.inputExclamation {
    left: 10px;

}

.buttonContainer {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.exclamationIcon {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
}

