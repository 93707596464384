@import '@/assets/styles/utils/vars';

.input {
    max-width: 140px;
}

// .content{
//     color: $first_color;
//     padding: 10px;
// }
.selectContent {
    left: unset;
    right: 0;
    max-width: 69px;
    bottom: 262px;
    // transform: translateY(100%);
    background: $fifth_color;
    border: 0.5px solid $third_color;
    border-radius: 4px;
    max-height: 500px;
    overflow: auto;
}

.active {
    color: $main_color;
}
