@import '@/shared/ui/Select/General.module.scss';

@import '@/shared/styles/arrow.module.scss';

.wrap {
    position: relative;
    // width: 515px;

    input {
        flex-basis: 100%;
    }

}

.arrow {
    bottom: unset;
    top: 18px;
}

.arrowUp {
    transform: rotate(180deg);
}


.content {
    bottom: 0;
    left: 0;
    width: 100%;
    position: absolute;
    transform: translateY(100%);
    user-select: none;
    z-index: 1000;

}
