@import '@/assets/styles/utils/vars';

.loginForm {
    max-width: 400px;
    height: 500px;
    margin: 0 auto;
    line-height: 1.4;
    text-align: center;

    .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        color: $first_color;
        margin-bottom: 7px;
    }

    .subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $second_color;
    }
}
