@import '@/assets/styles/utils/vars';

.title,
.subtitle {
    font-weight: 500;
    line-height: 140%;
}


.title {
    color: $first_color;
    font-size: 20px;
}

.subtitle {
    font-size: 16px;
    text-align: center;
    color: $second_color;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 432px;

}
.wrapper {
    padding-top: 151px;
}