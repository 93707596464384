.name {
    font-weight: 500;
    line-height: 19px;
}

.mail {
    font-size: 14px;
    line-height: 16px;
}

.userInfo {
    height: 40px;
}

.logout {
    cursor: pointer;

    &:hover {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
}