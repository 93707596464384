@import '@/shared/ui/Select/General.module.scss';

.modal {
    flex-basis: 622px;
    max-width: 622px;
    overflow: initial;
}

.select {
    width: 270px;
}
