@import '@/assets/styles/utils/vars';


.table {
    width: 1400px;
}

.cardCode {
    overflow: hidden;
}

.code {
    padding: 15px 27px;
    background: $fourth_color;
}