@import '@/assets/styles/utils/vars';

.item {
    color: $first_color  !important;
    border-top: 0.5 solid transparent !important;
    border-bottom: 0.5 solid transparent !important;
    &:hover {
        background: $third_color !important;
        border-color:$third_color !important;
        color: $main_link_color !important;
    }
}