@import '@/assets/styles/utils/vars';

.captionInput {
    display: block;
    text-align: left;
    color: $first_color;
    font-size: 14px;
    margin-bottom: 7px;
}

.label {
    display: block;
}

.link {
    color: $main_color;
}

.remember {
    font-size: 12px;
    line-height: 1.4;
}

.rememberLabel {
    cursor: pointer;
    color: $second_color;
    column-gap: 7px;

    & * {
        cursor: pointer;
    }
}

.resendLabel {
    color: $main_link_color;
    font-size: 12px;
    text-decoration: underline;
}