body {
  color: $second_color;
  font-family: $font-family, sans-serif;
  -webkit-text-size-adjust: none;
  background: #F8F8F8;
}

.relative {
  position: relative;
}

input {
  font-family: $font-family, sans-serif !important;
}

.option {
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: $first_color;
  font-size: 14px;
  cursor: pointer;

  & > label {
    display: flex;
    flex-basis: 100%;
    cursor: pointer;
    padding: 12px 24px;
  }

  border-top: 0.5px solid $third_color;

  &:hover,
  &.active {
    background: $fourth_color;
    color: $main_link_color;

  }
}

.--fluid {
  flex-basis: 100% !important;
  max-width: 100% !important;
}

.pointer {
  cursor: pointer;
}

.wrapper-modal-delete {
  flex-basis: 550px;
}

.button-delete {
  padding: 0px !important;
}

.text-no-wrap {
  white-space: nowrap;
}

.input {
  //flex-basis: 706px;
  //max-width: 706px;
  // flex-shrink: 0;
  margin-right: 14.15px;
}

.paginate {
  transform: translateX(-50%);

  li:not(:first-child, :last-child) {
    background: #FFFFFF;
    height: 32px;
    padding: 0px;
    width: 32px;
    border-radius: 5px;

    &:not(:first-child, :last-child) {
      margin-right: 6px;
    }

    button {
      margin: 0px;
      color: $first_color;


    }
  }
}

.paginateSelected {
  color: white !important;
  background-color: $main_color !important;
}

.button {
  flex-basis: 232px;
  flex-shrink: 0;
}

// .container {
//     display: grid;
//     padding: 20px 24px 20px 30px;
//     grid-template-columns: 300px 1054px;
//     column-gap: 26px;
// }
.main-container {
  display: flex;
  align-items: flex-start;
  column-gap: 28px;
  padding: 20px 30px 20px 30px;
}

.gap-20 {
  gap: 20px;
}

.page {
  width: calc(100% - 325px);
  //TODO
  //max-width: calc(100% - 325px);
  max-width: 1920px;
}

html,
body,
#root,
.wrapper {
  line-height: 1.4;;
  height: 100%;
}

button {
  user-select: none;
  cursor: pointer;
}

.wrapper {
  background: $bg_color;
}

img {
  display: block;
}

.plus {
  border: 1.5px solid #FFFFFF;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grow {
  flex-grow: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.block {
  display: block;
}

.required {
  color: $delete_color;
}

.react-datepicker__input-container {
  line-height: initial;
}

.flex {
  display: flex;


  &.row {
    flex-direction: row;
  }

  &.column {
    flex-direction: column;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  &.j {
    &-center {
      justify-content: center;
    }

    &-between {
      justify-content: space-between;
    }

    &-around {
      justify-content: space-around;
    }

    &-start {
      justify-content: flex-start;
    }

    &-end {
      justify-content: flex-end;
    }

    &-evenly {
      justify-content: space-evenly;
    }
  }

  &.a {
    &-center {
      align-items: center;
    }

    &-end {
      align-items: flex-end;
    }

    &-start {
      align-items: flex-start;
    }

    &-stretch {
      align-items: stretch;
    }
  }

  .self {
    &-end {
      align-self: flex-end;
    }
  }

  .a-content {
    &-start {
      align-content: flex-start;
    }
  }
}

.hr {
  display: block;
  height: 0.5px;
  background-color: $third_color;
}

.search-button {
  width: 37px !important;
  height: 37px !important;
}

.c_black {
  color: black;
}

.c_white {
  color: white;
}

.none-select {
  user-select: none;
}

.fit-content {
  width: fit-content;
}

.c_color_link {
  color: $main_link_color;
}

.c_done_color {
  color: $done_color;
}

.c_danger_color {
  color: $delete_color;
}

.bg_danger_color {
  background: $delete_color;
}

.bg_black {
  background: black;
}

.bg_white {
  background: white;
}

label {
  user-select: none;
}

.label {
  font-size: 16px;
  color: $first_color;
}

.bg_fourth_color {
  background: $fourth_color;
}

.c_first_color {
  color: $first_color;
}

.mb-50 {
  margin-bottom: 50px
}

.text_up {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.wrapperClassName {
  color: $first_color;
  border-radius: 5px;
  border: 1px solid $third_color;
}

.toolbarClassName {
  border: none !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.editorClassName {
  padding: 30px 10px 15px;
}

@include generate-fs;
@include generate-fw;
@include margin-classes(150);
@include generate-width(100);
