.searchInput {
    width: 100%;
}

.searchButton {
    flex-basis: 89px;
}



.filterRow {
    display: flex;
    height: 24px;
    align-items: center;
    font-size: 13px;
    line-height: 1.4;
    padding-left: 4px;
    user-select: none;
    margin-bottom: 15px;
}

.filterRow label{
    display: flex;
    column-gap: 8px;
    align-items: center;
    cursor: pointer;
}
