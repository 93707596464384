@import "@/assets/styles/utils/vars";

.menuLink {
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    column-gap: 15.25px;
    padding: 8px 0px 8px 12px;
    border-radius: 5px;
    white-space: nowrap;

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    &:hover {
        background-color: $fourth_color;

        .icon {
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
    }

    &:active {
        background: $third_color;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .icon {
        height: 24px;
    }

    &.active {
        background-color: $main_color;
        color: $fifth_color;

        .icon {
            fill: $fifth_color;
        }
    }

}

.name {
    white-space: nowrap;
}