@import "@/assets/styles/utils/vars";

.list {
  width: 100%;
  max-height: 180px;
  margin-bottom: 31px;
  overflow: auto;
  border-radius: 10px;
  border: 0.5px solid $third_color;

  .item {
    padding: 12px 25px;
    font-size: 12px;
    display: flex;
    align-items: center;
    column-gap: 6px;
    color: $first_color;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 0.5px solid $third_color;
    }
  }
}

.buttonContainer {
  max-width: 120px !important;
}
