*, 
*::before,
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


html{
    scroll-behavior: smooth;
}


h1,
h2,
h3,
h4,
h5,
h6{
    font-size: inherit;
    font-weight: inherit;
}

a{
    text-decoration: none;
    color: inherit;
}

li{
    list-style: none;
}

address{
    font-style: normal;
}

button{
    display: block;
    line-height: 0px;
    font-family: inherit;
    font-size: inherit;
}
pre{
    font-family: inherit;
    line-height: 16.8px;
    font-style: normal;
}
img{
    display: block;
}

th{
    font-weight: inherit;
    font-size: inherit;
}

button,
input{
    border: none;
    background: none;
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input::placeholder{
    font-family: inherit;
}

