@import '@/assets/styles/utils/vars';

.wrapper {
    overflow: auto;
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba($color: #000000, $alpha: 0.5);
}

@keyframes modal {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}
.header{
    > *{
        &:first-child{
            margin-right: 12px;
        }
    }
}
.modalTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;
    //margin-left: 12px;
    color: $first_color;
}

%line {
    content: "";
    width: 16px;
    height: 1.78px;
    background: #000000;
    position: absolute;
    top: 50%;
}

.close {
    top: 33px;
    right: 35px;
    width: 24px;
    height: 24px;
    position: absolute;
    cursor: pointer;

    &::after {
        @extend %line;
        transform: translateY(-50%) rotate(-45deg);
    }

    &::before {
        @extend %line;
        transform: translateY(-50%) rotate(45deg);

    }
}

.modal {
    margin: auto;
    position: relative;
    flex-basis: 595px;
    max-width: 595px;
    overflow: auto;
    padding: 33px 35px;
    background: $fifth_color;
    border: 0.5px solid $third_color;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    animation-name: modal;
    animation-timing-function: ease-in-out;
    animation-duration: 0.3s;
}

.success {
    flex-basis: 300px;
}

.message {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
}
