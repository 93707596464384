@import '@/assets/styles/utils/vars';

.container {
  border-radius: 12px;
  color: $first_color;
}

.header {
  padding: 16px 27px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;

  .icon {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: $main_color;
    cursor: pointer;
  }
}

.body {
  font-size: 14px;
  padding: 20px 27px;
  background: $fourth_color;
}
