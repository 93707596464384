@import "@/assets/styles/utils/vars";

.item {
  min-width: 210px;

  &.left {
    padding-right: 50px;
    border-right: 1px solid $third_color;
  }

}

