.table-border-radius {
  border-radius: 10px;
  overflow: hidden;

  thead {
    font-weight: 500;
  }
}

.table-letter {
  width: calc(100% + 54px);
  margin-left: -27px;
}

.table-head-text-left {
  thead {
    text-align: left;
  }
}

.table-head-sticky {
  thead {
    position: sticky;
    top: 0;
  }
}

.table-link {
  text-decoration: underline;
  color: $main_link_color;
  cursor: pointer;
}

table {
  border-top: 0.5px solid $third_color;
  width: 100%;
  color: $first_color;
  border-collapse: collapse;
  text-align: center;
}

thead {
  line-height: 19.6px;
  height: 50px;
  font-weight: 500;
  font-size: 16px;
  background-color: $fourth_color;

}

tr {
  height: 45px;
  border-bottom: 0.5px solid #EBEBEB;

}

.sorted-cell {
  cursor: pointer;
  align-items: center;
  column-gap: 6px;
  display: flex;
  justify-content: center;
}

.table-left {
  text-align: left;

  th, td {
    &:first-child {
      padding-left: 27px;
    }
  }

  th > div {
    justify-content: flex-start;

  }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 19.6px;
  }
}
