@import '@/assets/styles/utils/vars';

.expandedAll {
  user-select: none;
}

.expanded {
  color: $main_hover_color;
}

.expandedIcon {
  transform: rotate(180deg);
}

.scrollBar {
  > div {
    &:first-child {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}
