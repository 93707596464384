@import '@/shared/ui/Select/General.module.scss';

.filter {
    display: flex;
    align-items: center;
}

.select {
    flex-basis: 24.1%;
}

.input {
    flex-basis: 13.23%;
    max-width: 13.23%;
    margin-right: 10px;
    flex-grow: 0;
    flex-shrink: 0;

    input {
        flex-basis: 100%;
        width: 13.23%;
    }
}

.addressInput {
    flex-basis: 27.41%;
    flex-grow: 0;

    input {
        flex-basis: 100%;
    }
}
.table{
    width: 1900px;
    table-layout: fixed;
    background: white;
    @media (min-width: 1900px + 385px) {
        width: 100%;
    }
}
