.wrap {
    top: 0;
    left: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(4px);
    // background: #fff;
}

.container {
    flex-basis: 312px;
    max-width: 312px;
    height: 208px;
    display: flex;
    flex-direction: column;
    align-items: center;
}