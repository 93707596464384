@import "@/assets/styles/utils/vars";

.wrapper {
  color: $first_color;
}

.row {
  border-top: 0.5px solid $third_color;
  padding-top: 30px;
  padding-bottom: 30px;
  flex-wrap: wrap;
  column-gap: 20px;
}

.button {
  width: unset;
  display: inline-block;
  padding-left: 16px;
  padding-right: 16px;
}

.listItem {
  padding: 10px;
  cursor: pointer;
}

.scrollContainerWrapper {
  width: 100%;
  overflow: scroll;
}

.test {
  background-color: red;
  width: 2000px;
  height: 500px;
}

.scrollContainer {
  width: 100%;
  overflow: auto;
}

.table {
  width: 1500px;
  table-layout: fixed;
  @media (min-width: 1500 + 385px) {
    width: 100%;
  }
}

.dateFilter {
  row-gap: 20px;
}

.dateRangeStart {
  flex-basis: 536px;
}

.dateRangeEnd {
  flex-basis: 443px;
}

.toolPanel {
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.toolPanelButton {
  flex-basis: 45px;
  height: 45px;
}

.placeHolder {
  font-size: 12px;
  color: $second_color;
  max-width: 254px;
}


.searchButton {
  flex-basis: 89px;
}
