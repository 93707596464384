@import '@/assets/styles/utils/vars';

.sidePanel {
    width: 298px;
    flex-basis: 298px;
    padding: 20px 28px 53px 28px;
    color: $main_link_color;
    flex-shrink: 0;
    overflow: hidden;
}

.showHideButton {
    top: 54px;
    right: 0;
    transform: rotate(180deg) translateX(-50%);
    position: absolute;
}

.hide {
    transform: translateX(50%);
}

@keyframes hide-menu {
    from {
        width: 298px;
    }

    to {
        width: 102px;
    }
}

@keyframes open-menu {
    from {
        width: 102px;
    }

    to {
        width: 298px;
    }
}

.hideCard {
    animation-name: hide-menu;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;

}

.openCard {
    animation-name: open-menu;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-duration: 0.2s;
}

.hidePanel {
    overflow: hidden;
}

.card {
    position: relative;
}

