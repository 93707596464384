@import "@/assets/styles/utils/vars";
.list {
  width: 100%;
  max-height: 180px;
  margin-bottom: 31px;
  overflow: auto;
  border-radius: 10px;
  border: 0.5px solid $third_color;
  .item {
    padding: 12px 25px;
    font-size: 12px;
    color: $delete_color;
    &:not(:last-child) {
      border-bottom: 0.5px solid $third_color;
    }
  }
}
