@import "@/assets/styles/utils/vars";
.arrow {
  cursor: pointer;
  top: 13px;
  right: 22px;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4.18px 4.64px 4.18px;
  border-color: transparent transparent $first_color transparent;
}

.arrowDown {
  transform: rotate(180deg);
  top: initial;
  bottom: 14.23px;
}
