@import "@/assets/styles/utils/vars";

.row,
.rowProduct {
    display: flex;
    justify-content: space-between;
    padding-right: 220px;
    padding-left: 27px;
    border-top: 0.5px solid $third_color;
    &:last-child{
        border-radius: 0px 0px 10px 10px;
    }
}

.rowProduct {
    background-color: $fourth_color;
}