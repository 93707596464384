.table {
  width: 100%;
}

.inputGuide {
  flex-grow: 1;
}

.datePicker {
  max-width: 215px;
  flex-grow: 1;
}
