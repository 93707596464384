.item {
  flex-basis: 455px;

  input {
    flex-basis: 100%;
  }
}

.button {
  width: unset;
  display: inline-block;
  padding-left: 16px;
  padding-right: 16px;
}
