@import '@/assets/styles/utils/vars';

.logo {
    display: flex;
    column-gap: 9.66px;
    cursor: pointer;
    width: fit-content;
}

.text {
    font-weight: 500;
    color: $first_color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;

    .title {

        flex-shrink: 0;
        font-size: 20px;
        line-height: 23px;
    }

    .subTitle {
        flex-shrink: 0;
        font-size: 12px;
        line-height: 14px;
    }
}