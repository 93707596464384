@import '@/assets/styles/utils/vars';

.textarea {
    background: #FFFFFF;
    border: 1px solid #E6E7E9;
    border-radius: 5px;
    padding: 7px 17px;
    width: 100%;
    outline: none;
    font-family: 'Roboto';
    font-size: 12px;
    max-height: 408px;
    resize: none;
}

.textarea::-webkit-scrollbar {
    width: 8px;
}

.textarea::-webkit-scrollbar-track {
    background: none;
}

.textarea::-webkit-scrollbar-thumb {
    background: rgba(74, 74, 74, 0.3);
    border-radius: 5px;
}