//Width

$width-container: 1440px;
$max-width: 1920px;

//Colors

$main_color: #00ADEF;
$main_hover_color: #077AB3;
$main_press_color: #064A6B;
$main_link_color: #758EA8;

$bg_color: #F1F3F9;

$delete_color: #D94040;
$delete_hover_color: #811D1D;
$delete_press_color: #4E0404;

$done_color: #4AB568;

$first_color: #4A4A4A;
$second_color: #A5A5A5;
$third_color: #E6E7E9;
$fourth_color: #F9F9F9;
$fifth_color: #FFFFFF;

//fonts
$font-family: 'Roboto';
$font-size-list: 12, 13, 14, 18,65, 20, 22, 36, 24, 70, 25, 20, 15, 16;
$font-weight-list: 400, 500, 600, 700, 900;
$line-height: 1.4;