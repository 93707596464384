@import '@/assets/styles/utils/vars';
@import '@/shared/ui/Select/General.module.scss';


.select {
  width: 555px;
}

.reportTable {
  background: $fifth_color;
  text-align: left;

  th:first-child,
  td:first-child {
    padding-left: 20px;
  }

  th > div {
    justify-content: flex-start;
  }
}

.checkTable {
  margin-top: 20px;
  width: 2200px;
  table-layout: fixed;
  @media (min-width: 2200 + 385px) {
    width: 100%;
  }
}

//.marginBottom {
//    margin-bottom: 216px;
//}

.shiftNumber {
  width: 280px;
  flex-basis: 280px;
}

.button {
  display: inline-block;
  width: unset;
  padding-left: 22px;
  padding-right: 22px;

}
