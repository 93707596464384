@import '@/assets/styles/utils/vars';



.reportTable {
    background: $fifth_color;
    text-align: left;

    th:first-child,
    td:first-child {
        padding-left: 20px;
    }

    th>div {
        justify-content: flex-start;
    }
}

.header {
    h2 {

        color: $third_color;
    }
}

.closeButton {
    flex-basis: 120px;
    max-width: 120px;
}