@import "@/assets/styles/utils/vars";

.button {
  display: block;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
  height: 40px;
  color: $fifth_color;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-radius: 5px;
  &:disabled {
    background: $second_color;
    &:hover,
    &:active {
      background: $second_color;
    }
  }
  &:hover {
    background-color: $main_hover_color;
  }

  &:active {
    background-color: $main_press_color;
  }
}

.icon {
  flex-basis: 45px;
  max-width: 45px;
  height: 45px;
}

.simple {
  background-color: $main_color;
}

.cancel {
  border: 1px solid $third_color;
  background: $fourth_color;
  color: $main_link_color;

  &:hover {
    background: $third_color;
    color: $second_color;
  }

  &:active {
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.2);
  }
}

.danger {
  background-color: $delete_color;
  border: 1px solid $third_color;

  &:hover {
    background-color: $delete_hover_color;
  }

  &:active {
    background-color: $delete_press_color;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &:disabled {
    opacity: 0.5;
  }
}

.buttonRefresh {
  width: 15.71px;
  height: 15.71px;
  cursor: pointer;
}
