@import '@/assets/styles/utils/vars';

.modal {
    flex-basis: 455px;
    max-width: 455px;
    padding: 20px;
}

.progress {
    line-height: 0;
    height: 16px;
    width: 355px;
}

.wrapper {
    border: 1px solid #E6E7E9;
    padding: 15px;
}

.again {
    color: $main_link_color;
    font-size: 12px;
}