@import "@/assets/styles/utils/vars";

.field:not(:last-child) {
    margin-bottom: 8px;
}

.fieldFirst {
    margin-bottom: 11px;
}

.fieldName {
    color: $main_link_color;
    line-height: 19px;
}

.fieldValue {
    font-weight: 500;
}

.doneColor {
    color: $done_color;
}

.fieldsRow {
    padding-right: 163px;
    margin-bottom: 4px;
}