@import '@/assets/styles/utils/vars';

.searchLine {
    position: relative;
    //max-width: 873px;
    flex-basis: 100%;
    display: flex;

    .iconContainer {
        position: absolute;
        width: 18px;
        height: 18px;
        z-index: 100;
        top: 50%;
        transform: translateY(-50%);
        left: 16.94px;
    }

    .input {
        //max-width: 873px;
        flex-basis: 100%;
        padding-left: 40px;
        margin-right: 10px;
        &:-internal-autofill-selected {
            background: none;
        }

        &:focus,
        &:hover {
            border-color: $main_color;
        }
    }

    .closeButton {
        position: absolute;
        top: 50%;
        right: 13.67px;
        transform: translateY(-50%);

    }
}
