@import '@/assets/styles/utils/vars';

.wrapper {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
}

.radio,
.innerCircle,
.circle {
    position: absolute;
    top: 0;
    left: 0;
}

.radio {
    opacity: 0;
    width: 18px;
    height: 18px;
}

.radio:checked+.circle .innerCircle {
    display: block;
}

.circle {
    width: 18px;
    height: 18px;
    cursor: pointer;
    border: 2px solid $third_color;
    border-radius: 50%;
}

.innerCircle {
    width: 100%;
    height: 100%;
    display: none;
    background: $main_color;
    border-radius: 50%;
}