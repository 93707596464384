@import '@/assets/styles/utils/vars';
@import '@/shared/styles/arrow.module.scss';

.selectContent {
  background: $fifth_color;
  border: 0.5px solid $third_color;
  border-radius: 4px;
  max-height: 500px;
  overflow: auto;
}
