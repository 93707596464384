@import './utils/vars';

.modal-form {
    .label {
        display: flex;
        row-gap: 7px;
        flex-direction: column;
        align-items: stretch;
        margin-bottom: 14px;
        font-size: 14px;
        line-height: 1.4;
    }

    .select {
        height: 40px;
        border: 1px solid $third_color;
    }

    .button {
        width: 120px;
        flex-basis: 120px;
    }

    .column {
        flex-basis: 277px;
    }
}
